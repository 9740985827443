import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Event, NavigationEnd, Router} from '@angular/router';
import {Location} from '@angular/common';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {distinctUntilChanged, filter, map, shareReplay} from 'rxjs/operators';
import {AuthenticationService, WorkspacesService} from '../../../../core/services';

import {IAuthUser} from '../../../../models/token-user.model';
import {TranslateService} from '@ngx-translate/core';
import {notBlank} from 'src/app/shared/StringUtils';
import {ResourceType} from '../../../../models/workspaces.model';


interface IBreadCrumb {
    label: string;
    url: string;
}

@Component({
    selector: 'app-top-bar',
    templateUrl: './top-bar.component.html',
    styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent implements OnInit, OnDestroy {


    public authUser: IAuthUser;
    public workspaceId: string;
    public workspaceName = new BehaviorSubject('');
    public resourceType = ResourceType.INVOICE;

    private resolveWpSub: Subscription;

    public breadcrumbs: IBreadCrumb[];
    isHandset$: Observable<boolean> = this.breakpointObserver
        .observe(Breakpoints.Handset)
        .pipe(
            map((result) => result.matches),
            shareReplay()
        );

    constructor(
        private breakpointObserver: BreakpointObserver,
        private authenticationService: AuthenticationService,
        private workspaceService: WorkspacesService,
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        private translate: TranslateService) {
        router.events
            .pipe(
                filter((event: Event) => event instanceof NavigationEnd),
                distinctUntilChanged()
            )
            .subscribe(() => {
                this.breadcrumbs = this.buildBreadCrumb(this.route);
            });
        this.resolveWorkspace(); // async is better ?
    }

    // TOOD: simplify

    private resolveWorkspace() {

        this.resolveWpSub = this.workspaceService.workspaceId.subscribe(
            (wpId) => {
                if (notBlank(wpId)) {
                    this.workspaceId = wpId;
                    this.workspaceService.getWorkspace(this.workspaceId).toPromise()
                        .then((workspace) => {
                                if (!this.location.path().endsWith('/workspaces') && !this.location.path().endsWith('/poworkspaces')) {
                                    this.workspaceName.next(workspace.name);
                                } else {
                                    this.translate
                                        .get('topBar.listEntreprise')
                                        .subscribe((value) => (this.workspaceName.next(value)));
                                }

                                this.resourceType = workspace.resourceType;
                            }
                        );
                }
            }
        );

    }

    ngOnDestroy(): void {
        this.resolveWpSub.unsubscribe();
    }

    ngOnInit(): void {
        this.authUser = this.authenticationService.getUserAuthData();
    }


    /**
     * Check the current url to show/hide the sidenav btn
     */
    public isSideNavActive(): boolean {
        if (this.location.path().endsWith('/workspaces') || this.location.path().endsWith('/poworkspaces')) {
            return false;
        } else {
            return true;
        }
    }


    private buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: IBreadCrumb[] = []): IBreadCrumb[] {
        // If no routeConfig is available we are on the root path
        let label: string;
        label = this.getValue(route);
        /*let label =
            route.routeConfig && route.routeConfig.data
                ? route.routeConfig.data.breadcrumb
                : '';*/
        let path = route.routeConfig && route.routeConfig.data ? route.routeConfig.path : '';

        // If the route is dynamic route such as ':id', remove it
        const lastRoutePart = path.split('/').pop();
        const isDynamicRoute = lastRoutePart.startsWith(':');
        if (isDynamicRoute && !!route.snapshot) {
            const paramName = lastRoutePart.split(':')[1];
            path = path.replace(lastRoutePart, route.snapshot.params[paramName]);
            label = this.getValue(route);
        }

        // In the routeConfig the complete path is not available, so we rebuild it each time
        const nextUrl = path ? `${url}/${path}` : url;

        const breadcrumb: IBreadCrumb = {
            label,
            url: nextUrl,
        };
        // Only adding route with non-empty label
        const newBreadcrumbs = breadcrumb.label
            ? [...breadcrumbs, breadcrumb]
            : [...breadcrumbs];
        if (route.firstChild) {
            // If we are not on our current path yet, there will be more children to look after, to build our breadcumb
            return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
        }
        return newBreadcrumbs;
    }

    private getValue(route: ActivatedRoute) {
        let label: string;
        if (route.routeConfig &&
            route.routeConfig.data &&
            route.routeConfig.data.breadcrumb) {
            this.translate.get(route.routeConfig.data.breadcrumb)
                .subscribe((value) => {
                    label = value;
                });
        } else {
            label = '';
        }
        return label;
    }

    logout(): void {
        this.authenticationService.logout();
    }

    isLoggedIn() {
        return this.authenticationService.isLoggedIn();
    }


}
