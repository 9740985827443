import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {AuthGuard, CanDeactivateGuard} from './core/guards';

import {WorkspaceInfoComponent} from './modules/params/components/workspace-info/workspace-info.component';
import {WorkspaceCategoriesComponent} from './modules/params/components/workspace-categories/workspace-categories.component';
import {WorkspaceSuppliersComponent} from './modules/params/components/workspace-suppliers/workspace-suppliers.component';
import {ParamsComponent} from './modules/params/params.component';
import {KontaAppComponent} from './modules/konta-app/konta-app.component';
import {InvoiceProcessingComponent} from './modules/invoice-processing/invoice-processing.component';
import {CanClaimTaskGuard} from './core/guards/can-activate.guard';
import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component';
import {TaskDetailsComponent} from './modules/task-details/task-details.component';
import {ActivityOverviewComponent} from './modules/activity-overview/activity-overview.component';
import {WorkspacesComponent} from './modules/workspaces/workspaces.component';
import {InvoiceExplorerComponent} from './modules/invoice-explorer/invoice-explorer.component';
import {InvoiceRejectedExplorerComponent} from './modules/invoice-rejected-explorer/invoice-rejected-explorer.component';
import {ClearFilterGuard} from './core/guards/clear-filter.guard';
import {InvoiceInProgressExplorerComponent} from './modules/invoice-inprogress-explorer/invoice-inprogress-explorer.component';
import {PermissionAuthGuard} from './core/guards/permission-auth.guard';
import {PoActivityOverviewComponent} from './modules/activity-overview-po/po-activity-overview.component';
import {PoProcessingComponent} from './modules/po-processing/po-processing.component';
import {PoTaskDetailsComponent} from './modules/po-task-details/po-task-details.component';
import {CanDeactivatePoGuard} from './core/guards/can-deactivate-po.guard';
import {POExplorerComponent} from './modules/po-explorer/po-explorer.component';
import {PoRejectedExplorerComponent} from './modules/po-rejected-explorer/po-rejected-explorer.component';
import {OngoingPoExplorerComponent} from './modules/ongoing-po-explorer/ongoing-po-explorer.component';

const routes: Routes = [
    {
        path: 'workspaces',
        component: KontaAppComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: '',
        },
        children: [
            {
                path: '',
                component: WorkspacesComponent,
                canActivate: [AuthGuard],
                data: {
                    breadcrumb: '',
                },
            },
            {
                path: ':workspaceId',
                component: ActivityOverviewComponent,
                canActivate: [PermissionAuthGuard],
                data: {
                    breadcrumb: '',
                    permissions: {
                        only: ['workspace.viewer']
                    }
                },
            },
            {
                path: ':workspaceId/tasks',
                component: TaskDetailsComponent,
                canActivate: [PermissionAuthGuard],
                canDeactivate: [ClearFilterGuard],
                data: {
                    breadcrumb: 'global.tasksList',
                    permissions: {
                        only: ['tasks.user']
                    }
                },
            },
            {
                path: ':workspaceId/purchase-invoices',
                component: InvoiceExplorerComponent,
                canActivate: [PermissionAuthGuard],
                data: {
                    breadcrumb: 'global.purchasingInvoices',
                    permissions: {
                        only: ['explorer.approved.editor', 'explorer.approved.viewer']
                    }
                },
            },
            {
                path: ':workspaceId/rejected-invoices',
                component: InvoiceRejectedExplorerComponent,
                canActivate: [PermissionAuthGuard],
                data: {
                    breadcrumb: 'global.rejectedTaskPageTitle',
                    permissions: {
                        only: ['explorer.rejected.editor', 'explorer.rejected.viewer']
                    }
                }
            }, {
                path: ':workspaceId/in-progress-invoices',
                component: InvoiceInProgressExplorerComponent,
                canActivate: [PermissionAuthGuard],
                data: {
                    breadcrumb: 'global.ongoingTaskPageTitle',
                    permissions: {
                        only: ['explorer.ongoing.editor', 'explorer.ongoing.viewer']
                    }
                }
            },
            {
                path: ':workspaceId/params',
                component: ParamsComponent,
                canActivate: [AuthGuard],
                children: [
                    {path: 'general', component: WorkspaceInfoComponent},
                    {
                        path: 'categories',
                        component: WorkspaceCategoriesComponent,
                    },
                    {
                        path: 'suppliers',
                        component: WorkspaceSuppliersComponent,
                    },
                    {
                        path: '',
                        redirectTo: 'general',
                        pathMatch: 'full',
                    },
                ],
            },
            {
                path: ':workspaceId/tasks/:taskId',
                component: InvoiceProcessingComponent,
                canActivate: [AuthGuard, CanClaimTaskGuard],
                canDeactivate: [CanDeactivateGuard],
                data: {
                    breadcrumb: 'global.tasksList',
                },
            },
            {
                path: '',
                redirectTo: 'workspaces',
                pathMatch: 'full',
            },
        ],
    },
    {
        path: 'poworkspaces',
        component: KontaAppComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: '',
        },
        children: [
            {
                path: '',
                component: WorkspacesComponent,
                canActivate: [AuthGuard],
                data: {
                    breadcrumb: '',
                },
            },
            {
                path: ':workspaceId',
                component: PoActivityOverviewComponent,
                canActivate: [PermissionAuthGuard],
                data: {
                    breadcrumb: '',
                    permissions: {
                        only: ['workspace.viewer']
                    }
                },
            },
            {
                path: ':workspaceId/tasks',
                component: PoTaskDetailsComponent,
                canActivate: [PermissionAuthGuard],
                canDeactivate: [ClearFilterGuard],
                data: {
                    breadcrumb: 'global.tasksList',
                    permissions: {
                        only: ['tasks.user']
                    }
                },
            },
            {
                path: ':workspaceId/purchase-orders',
                component: POExplorerComponent,
                canActivate: [PermissionAuthGuard],
                data: {
                    breadcrumb: 'global.approvedOrders',
                    permissions: {
                        only: ['explorer.approved.editor', 'explorer.approved.viewer']
                    }
                },
            },
            {
                path: ':workspaceId/ongoing-purchase-orders',
                component: OngoingPoExplorerComponent,
                canActivate: [PermissionAuthGuard],
                data: {
                    breadcrumb: 'ongoingPoExplorer.ongoingOrders',
                    permissions: {
                        only: ['explorer.ongoing.editor', 'explorer.ongoing.viewer']
                    }
                },
            },
            {
                path: ':workspaceId/rejected-purchase-orders',
                component: PoRejectedExplorerComponent,
                canActivate: [PermissionAuthGuard],
                data: {
                    breadcrumb: 'global.rejectedOrders',
                    permissions: {
                        only: ['explorer.rejected.editor', 'explorer.rejected.viewer']
                    }
                },
            },
            {
                path: ':workspaceId/rejected-invoices',
                component: InvoiceRejectedExplorerComponent,
                canActivate: [PermissionAuthGuard],
                data: {
                    breadcrumb: 'Factures rejetées',
                    permissions: {
                        only: ['explorer.rejected.editor', 'explorer.rejected.viewer']
                    }
                }
            },
            /*{
                path: ':workspaceId/in-progress-invoices',
                component: InvoiceInProgressExplorerComponent,
                canActivate: [PermissionAuthGuard],
                data: {
                    breadcrumb: 'Factures en cours de traitement',
                    permissions: {
                        only: ['explorer.ongoing.editor', 'explorer.ongoing.viewer']
                    }
                }
            },*/
            {
                path: ':workspaceId/tasks/:taskId',
                component: PoProcessingComponent,
                canActivate: [AuthGuard, CanClaimTaskGuard],
                canDeactivate: [CanDeactivatePoGuard],
                data: {
                    breadcrumb: 'global.tasksList',
                },
            },
            {
                path: '',
                redirectTo: 'poworkspaces',
                pathMatch: 'full',
            },
        ],
    },
    {
        path: 'notfound',
        component: PageNotFoundComponent,
    },
    {
        path: '**',
        redirectTo: 'workspaces',
        pathMatch: 'full',
    },
    {
        path: '',
        redirectTo: 'workspaces',
        pathMatch: 'full',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
